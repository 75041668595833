import React, { Component } from 'react';


class Footer extends Component {
    render() {
    return (
      <div className="footer">
       <p>Stephen Chen 2019</p>
      </div>
    );
  }
  }
  
  export default Footer;
  